import req from "../methods/req"
import method from '../methods/method'

/**
 * 获取客户列表
 * @param {String} customer_type eg: customer_type:'dealer'，目前该字段只有dealer值，用于只筛选出经销商
 */
export const getCustomerList = function ({
    _this,
    data
}) {
    return method.get_list({
        _this,
        url: '/manage/crm.customer/getCustomerList',
        data
    })
}
// 客户重复拜访数
export const customerVisitRepeat = function (data) {
    return req({
        url: '/manage/crm.visit/customerVisitRepeat',
        ...data
    })
}

// 子集manage/crm.visit/getSub
export const VisitRepeatgetSub = function (data) {
    return req({
        url: '/manage/crm.visit/getSub',
        ...data
    })
}

// 获取客户编辑数据
export const getModifyCustomerInfo = function (data) {
    return req({
        url: '/manage/crm.customer/getModifyCustomerInfo',
        data
    })
}

// 获取客户信息
export const getCustomerDetails = function (data) {
    return req({
        url: '/manage/crm.customer/getCustomerDetails',
        data
    })
}


//添加编辑客户数据
export const modify = function (data) {
    return req({
        url: '/manage/crm.customer/modify',
        ...data
    })
}

//批量转移客户
export const batchModify = function (data) {
    return req({
        url: '/manage/crm.customer/batchModify',
        ...data
    })
}

// 获取客户等级列表
export const getCustomerLevel = function (data) {
    return method.get_list({
        url: '/manage/crm.customer/getCustomerLevel',
        ...data
    })
}

// 修改客户等级
export const changeCustomerLevel = function (data) {
    return req({
        url: '/manage/crm.customer/changeCustomerLevel',
        ...data
    })
}

// 获取客户类型列表
export const groupList = function (data) {
    return req({
        url: '/manage/crm.customer/groupList',
        ...data
    })
}

// 修改客户类型
export const changeGroup = function (data) {
    return req({
        url: '/manage/crm.customer/changeGroup',
        ...data
    })
}

// 用户线路
export const lineList = function (data) {
    return method.get_list({
        url: '/manage/crm.customer/lineList',
        ...data
    })
}

// 用户线路转移
export const lineTransfer = function (data) {
    return req({
        url: '/manage/crm.customer/lineTransfer',
        ...data
    })
}



// 用户线路详情
export const lineInfo = function (data) {
    return req({
        url: '/manage/crm.customer/lineInfo',
        ...data
    })
}


// 用户线路客户列表
export const lookCustomer = function (data) {
    return method.get_list({
        url: '/manage/crm.customer/lookCustomer',
        ...data
    })
}

// 拜访记录列表
export const visitList = function (data) {
    return method.get_list({
        url: '/manage/crm.customer/visitList',
        ...data
    })
}

export const visitInfo = function (data) {
    return req({
        url: '/manage/crm.customer/visitInfo',
        ...data
    })
}

export const censusDepartment = function (data) {
    return req({
        url: '/manage/crm.customer/censusDepartment',
        ...data
    })
}

export const censusDepartmentSon = function (data) {
    return req({
        url: '/manage/crm.customer/censusDepartmentSon',
        ...data
    })
}

// 展开部门时获取部门下的子部门及人员
export const censusDepartmentV2 = function (data) {
    return req({
        url: '/manage/crm.visit/censusDepartmentV2',
        ...data
    })
}



export const censusChart = function (data) {
    return req({
        url: '/manage/crm.customer/censusChart',
        ...data
    })
}

// 获取报表顶部图标
export const censusChartV2 = function (data) {
    return req({
        url: '/manage/crm.visit/censusChartV2',
        ...data
    })
}

export const delCustomer = function (data) {
    return req({
        url: '/manage/crm.customer/delCustomer',
        ...data
    })
}

export const enableCustomer = function (data) {
    return req({
        url: '/manage/crm.customer/enableCustomer',
        ...data
    })
}



export const sealedCustomer = function (data) {
    return req({
        url: '/manage/crm.customer/sealedCustomer',
        ...data
    })
}

// 客户拜访统计
export const userStatistics = function (data) {
    return method.get_list({
        url: '/manage/crm.freezer/userStatistics',
        ...data
    })
}

// 客户照片统计
export const customerPhoto = function (data) {
    return method.get_list({
        url: '/manage/crm.freezer/customerPhoto',
        ...data
    })
}

// 客户照片统计详情
export const photoInfo = function (data) {
    return method.get_list({
        url: '/manage/crm.freezer/photoInfo',
        ...data
    })
}

// 冰柜审核列表
export const getExamineList = function (data) {
    return method.get_list({
        url: '/manage/crm.freezer/getExamineList',
        ...data
    })
}

// 冰柜审核
export const bingingExamine = function (data) {
    return req({
        url: '/manage/crm.freezer/bingingExamine',
        ...data
    })
}

// 拜访统计
export const userPhoto = function (data) {
    return req({
        url: '/manage/crm.freezer/userPhoto',
        ...data
    })
}

// 拜访统计
export const userPhotoSon = function (data) {
    return req({
        url: '/manage/crm.freezer/userPhotoSon',
        ...data
    })
}

// 删除线路
export const removeLine = function (data) {
    return req({
        url: '/manage/crm.visit/removeLine',
        ...data
    })
}

// 客户统计
export const customerReport = function (data) {
    return req({
        url: '/manage/crm.report/customerReport',
        ...data
    })
}

// 客户统计
export const customerReportSon = function (data) {
    return req({
        url: '/manage/crm.report/customerReportSon',
        ...data
    })
}

// 客户统计
export const getLevel = function (data) {
    return req({
        url: '/manage/crm.report/getLevel',
        ...data
    })
}

// 操作日志列表
export const getLog = function ({
    _this,
    data
}) {
    return method.get_list({
        _this,
        url: '/manage/crm.customer/getLog',
        data
    })
}

// 获取日志详情
export const getLogContent = function (data) {
    return req({
        url: '/manage/crm.customer/getLogContent',
        ...data
    })
}


// 同步经销商
export const push_seller = function (data) {
    return req({
        url: '/manage/crm.customer/push',
        ...data
    })
}

// 同步分销商
export const push_distributor = function (data) {
    return req({
        url: '/manage/crm.customer/pushDistribute',
        ...data
    })
}

// 导出功能表格主要内容为线路名+客户数
export const lineExport = function (data) {
    return req({
        url: '/manage/crm.export/lineExport',
        ...data
    })
}

// 获取用户上次设置的最大线路数量
export const getLineNum = function (data) {
    return req({
        url: '/manage/crm.visit/getLineNum',
        ...data
    })
}
// 设置最大线路数量
export const setLineNum = function (data) {
    return req({
        url: '/manage/crm.visit/setLineNum',
        ...data
    })
}

// 出差列表
export const getTripList = function (data) {
    return method.get_list({
        url: '/manage/crm.trip_visit/getTripList',
        ...data
    })
}

// 出差拜访列表
export const getTripVisitRecord = function (data) {
    return req({
        url: '/manage/crm.trip_visit/getTripVisitRecord',
        ...data
    })
}

// 绑定动销小程序
export const cancelBinding = (data) => req({
    url: '/manage/crm.customer/cancelBinding',
    ...data
})

// 批量修改经销商
export const batchDistributor = (data) => req({
    url: '/manage/crm.customer/batchDistributor',
    ...data
})

// 获取客户id
export const getCustomerIds = (data) => req({
    url: '/manage/crm.customer/getCustomerIds',
    ...data
})


// 获取客户性质列表
export const getCustomerNatureList = function (data) {
    return method.get_list({
        url: '/manage/crm.customer_nature/getCustomerNatureList',
        ...data
    })
}

export const saveCustomerNature = (data) => req({
    url: '/manage/crm.customer_nature/saveCustomerNature',
    ...data
})
export const getCustomerNatureSelect = (data) => req({
    url: '/manage/crm.customer_nature/getCustomerNatureSelect',
    ...data
})


export const delCustomerNature = (data) => req({
    url: '/manage/crm.customer_nature/delCustomerNature',
    ...data
})